.QuestionnairePreview-noContent-container {
    width: 100%;
    min-height: 200px;
    background-color: var(--color-primary-light);
    border-radius: var(--border-radius-md);
    box-shadow: var(--box-shadow);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.QuestionnairePreview--item--wrapper {
    display: flex;
    width: 100%;
}

.QuestionnairePreview--item--icon {
    cursor: pointer;
   margin: 0 4px;
   border: 1px solid var(--color-primary-dark);
}

.QuestionnairePreview--item--icons--container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.QuestionnairePreview--title {
    font-size: var(--fs-h3);
    line-height: var(--fs-h3);
    font-weight: 400;
    color: var(--color-text-primary);
    margin-top: 24px;
}

.QuestionnairePreview--text {
    font-size: var(--fs-body);
    color: var(--color-text-secondary);
}

.QuestionnairePreview-question--title {
    font-size: var(--fs-body);
    font-weight: 400;
    color: var(--color-text-secondary);
}

.QuestionnairePreview-question--text {
    font-size: var(--fs-body);
    font-weight: 400;
    color: var(--color-text-primary);
}

.QuestionnairePreview--answerText {
    font-size: var(--fs-small);
    color: var(--color-text-primary);
}

.QuestionnairePreview--correctAnswer {
    font-weight: bold;
    text-decoration: underline;
}