.notesAndCertificates-mainDiv {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  height: 100%;
}

.notesAndCertificates-noContentMainDiv {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.notesAndCertificates-noContent-text{
    font-size: var(--fs-body);
}

.notesAndCertificates-headerDiv {
  display: flex;
}

.notesAndCertificates-titlesDiv {
  display: flex;
  flex-direction: column;
}

.notesAndCertificates-headerQuantity-acaUnitName {
  margin: 8px 0;
}

.notesAndCertificates-headerQuantity-acaUnitName-badge {
  font-size: var(--fs-h6) !important;
  font-weight: 500 !important;
  word-break: break-word !important;
  white-space: pre-wrap !important;
  padding: 12px !important;
  width: 100% !important;
}

.notesAndCertificates-buttonRoot {
  display: flex;
  margin-left: auto;
}

.notesAndCertificatesTable-downloadIcon {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.notesAndCertificatesTable-downloadIconDisabled {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  cursor: not-allowed;
  opacity: 0.5;
}

.notesAndCertificates--badgeGreen {
  background-color: var(--color-badge-green) !important;
  font-weight: 500 !important;
  font-size: var(--fs-small) !important;
}

.notesAndCertificates--badgeYellow {
  background-color: var(--color-badge-yellow) !important;
  font-weight: 500 !important;
  font-size: var(--fs-small) !important;
}

.notesAndCertificates--badgeOrange {
  background-color: var(--color-badge-orange) !important;
  font-weight: 500 !important;
  font-size: var(--fs-small) !important;
}

.notesAndCertificates--badgeRed {
  background-color: var(--color-badge-red) !important;
  font-weight: 500 !important;
  font-size: var(--fs-small) !important;
}

.notesAndCertificates--noBadge {
  background-color: transparent !important;
  color: var(--color-text-primary) !important;
  font-weight: 500 !important;
  font-size: var(--fs-small) !important;
}

/* Color references for under table information */

.notesAndCertificatesTable-colorReferences {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4px 0 16px 0;
  gap: 8px;
}

.notesAndCertificatesTable-colorReference-headerDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

.notesAndCertificatesTable-colorReference-p-header {
  font-size: var(--fs-small);
  line-height: calc(var(--fs-small) * 1.2);
}

.notesAndCertificatesTable-colorReferences-div {
  display: flex;
  gap: 8px;
}

.notesAndCertificatesTable-colorReferences-span {
  display: flex;
}

.notesAndCertificatesTable-colorReference-p-Styling {
  display: flex;
  align-items: center;
  font-size: var(--fs-tiny);
  line-height: calc(var(--fs-tiny) * 1.2);
}

.notesAndCertificatesTable-colorReferences-IconGreen {
  color: var(--color-badge-green) !important;
}

.notesAndCertificatesTable-colorReferences-IconYellow {
  color: var(--color-badge-yellow) !important;
}

.notesAndCertificatesTable-colorReferences-IconOrange {
  color: var(--color-badge-orange) !important;
}

.notesAndCertificatesTable-colorReferences-IconRed {
  color: var(--color-badge-red) !important;
}

@media (max-width: 768px) {
  .notesAndCertificates-headerDiv {
    display: flex;
    flex-direction: column-reverse;
  }
}
