.AdministrativeUserDetails--container{
   width: 100%;
   height: 100%;
   display: flex;
   flex-direction: column;
   gap: 30px;
   
}

.AdministrativeUserDetails-card-headerContainer{
   position: relative;
}

.AdministrativeUserDetails-card---userType{
   position: absolute;
   top: 0;
   left: 0;
}

.AdministrativeUserDetails--username{
   font-size: var(--fs-h4);
   color: var(--color-text-secondary);
}

.AdministrativeUserDetails--itemText{
   font-size: var(--fs-body);
   color: var(--color-text-primary);
   text-wrap: wrap;
   word-break: break-word;
   text-align: center;
}

.AdministrativeUserDetails--academicUnitsName{
   font-size: var(--fs-body);
   color: var(--color-text-primary);
   text-align: center;
}

.AdministrativePaymentModal--badge{
   font-size: var(--fs-tiny);
   padding: 2px 12px;
   color: #fff;
   border-radius: var(--border-radius-md);
   text-transform: uppercase;
}

.AdministrativePaymentModal--pending{
   background-color: rgb(206, 192, 0);
}

.AdministrativePaymentModal--success{
   background-color: rgb(49, 160, 49);
}

.AdministrativePaymentModal--cancelled{
   background-color: rgb(238, 22, 22);
}

.AdministrativePaymentModal--default{
   background-color: rgb(149, 149, 149);
}