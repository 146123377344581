.academicValorationsContainer__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
  height: 100%;
}

.academicValorationsContainer-iconComments {
  cursor: pointer;
}

.academicValorationsContainer-greenBadge {
  background-color: var(--color-badge-green) !important;
  font-weight: 500 !important;
  font-size: var(--fs-body) !important;
}
.academicValorationsContainer-redBadge {
  background-color: var(--color-badge-red) !important;
  font-weight: 500 !important;
  font-size: var(--fs-body) !important;
}
.academicValorationsContainer-yellowBadge {
  background-color: var(--color-badge-yellow) !important;
  font-weight: 500 !important;
  font-size: var(--fs-body) !important;
}
.academicValorationsContainer-orangeBadge {
  background-color: var(--color-badge-orange) !important;
  font-weight: 500 !important;
  font-size: var(--fs-body) !important;
}
.academicValorationsContainer-divBadge {
  display: flex;
  align-items: center;
  justify-content: center;
}

.academicValorationsContainer-colorReferences {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4px 0 16px 0;
  gap: 8px;
}

.academicValorationsContainer-colorReferences-div {
  display: flex;
  gap: 8px;
}

.academicValorationsContainer-colorReferences-span {
  display: flex;
}

.academicValorationsContainer-colorReference-p-Styling {
  display: flex;
  align-items: center;
  font-size: var(--fs-tiny);
  line-height: calc(var(--fs-tiny) * 1.2);
}

.academicValorationsContainer-colorReferences-IconGreen {
  color: var(--color-badge-green);
}
.academicValorationsContainer-colorReferences-IconOrange {
  color: var(--color-badge-orange);
}

.academicValorationsContainer-colorReferences-IconYellow {
  color: var(--color-badge-yellow);
}

.academicValorationsContainer-colorReferences-IconRed {
  color: var(--color-badge-red);
}

.academicValorationsContainer-descriptionReference-div {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.academicValorationsContainer-descriptionReference {
  font-size: var(--fs-tiny);
}
