.eventCalendarForm__container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
}

.eventCalendarForm__preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
    height: auto;
    flex-grow: 1;
}

.eventCalendarForm__editor {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    box-shadow: 0 -8px 24px 0 rgba(0,0,0,0.25);
    padding: 16px 32px;
}

.eventCalendarForm__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.mktgNotificationsForm__form__button {
    /*width: 100%;*/
    margin-top: 16px;
}