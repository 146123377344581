.AllQuestionModal--modalTitle{
   font-size: var(--fs-h3) !important;
   color: var(--color-text-secondary);
   width: 100% !important;
}


.AllQuestionModal--loader-container{
   display: grid;
   place-items: center;
   width: 100%;
   height: 100%;
}

.AllQuestionModal-questionGroup{
   width: 100%;
   display: flex;
   justify-content: space-between;
   align-items: center;
}

.AllQuestionModal--textContainer{
   display: flex;
   flex-direction: column;
   gap: 0;

}

.AllQuestionModal--title{
   font-size: var(--fs-body);
   font-weight: 400;
   color: var(--color-text-secondary);
}

.AllQuestionModal--questionText{
   font-size: var(--fs-small);
   color: var(--color-text-primary);
}

.AllQuestionModal--buttonContainer{
   width: 100%;
   padding: 20px 2px;
   display: flex;
   align-items: center;
   justify-content: center;
   gap: 10px;
}

.AllQuestion-noContent-text{
   font-size: var(--fs-body);
   color: var(--color-text-secondary);
   text-align: center;
   text-wrap: balance;
}