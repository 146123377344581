.AdministrativeUserDetails-card-headerContainer{
   position: relative;
}

.AdministrativeUserDetails-card---userType{
   position: absolute;
   top: 0;
   left: 0;
}

.AdministrativeUserDetails--username{
   font-size: var(--fs-h4);
   color: var(--color-text-secondary);
}

.AdministrativeUserDetails--itemText{
   font-size: var(--fs-body);
   color: var(--color-text-primary);
   text-wrap: wrap;
   word-break: break-word;
   text-align: center;
}