.sidebar-aside {
  position: fixed;
  max-width: var(--sidebar-width);
  width: 100%;
  height: 100vh;
  max-height: 100%;
  padding: 8px;
  transition: var(--sidebar-transition);
  z-index: 100;
}

.sidebar-nav {
  display: flex;
  gap: 32px;
  flex-direction: column;
  background-color: var(--color-primary-dark);
  width: 100%;
  height: 100%;
  padding: 16px;
  border-radius: var(--border-radius-sm);
}

.sidebar-burger {
  margin-left: auto;
}

.sidebar-burger-block {
  display: block;
  transition: all 1s ease-in-out;
}

.sidebar-burger-none {
  display: none;
  transition: all 1s ease-in-out;
}

.sidebar-links {
  flex: 1;
}

.sidebar-innerLinks {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.sidebar-footer {
  margin-top: auto;
  width: 100%;
}

@media (max-width: 1000px) {
  .sidebar-aside {
    position: unset;
    max-width: none;
    width: 100%;
    height: auto;
    max-height: 100%;
    padding: 8px;
    transition: var(--sidebar-transition);
    z-index: 100;
  }

  .sidebar-nav {
    justify-content: center;
    gap: 0;
  }

  .sidebar-header {
    display: flex;
    flex-direction: row;
    height: auto;
  }

  .sidebar-links {
    flex: none;
  }
}
