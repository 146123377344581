.academicUnitsForm__wrapper {
    width: 100%;
    height: auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.academicUnitsForm__button--cancel {
    margin-bottom: 24px;
    align-self: flex-end;
}

.academicUnitsForm__form {
    width: 100%;
    height: auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.academicUnitsForm__container {
    width: 100%;
    height: auto;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    align-self: center;

    box-shadow: var(--box-shadow-md);
    border-radius: var(--border-radius-md);
}

.academicUnitsForm__header {
    width: 100%;
    height: auto;

    display: flex;
    flex-direction: row;
    justify-content: center;

    border-bottom: 1px solid var(--color-primary-darkest);
}

.academicUnitsForm__body {
    width: 100%;
    height: auto;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 24px 32px;
}

.academicUnitsForm__button {
    margin: 24px 0;
}

