.salesDashboardStats-father-div{
    width: 100%;
    height: 100%;
}

.salesDashboardStats-paper-root{
    height: 100%;
}

.salesDashboardStats-content-father{
    display: flex;
}

.salesDashboardStats-icon{
    margin-left: auto;
}

.salesDashboardStats-students{
    display: flex;
    justify-content: center;
    font-size: var(--fs-body);
    line-height: calc(var(--fs-body)*1.4);
}

.salesDashboardStats-students-icon{
    margin-right: 8px;
}