.pricingForm__title {
    font-family: var(--font-primary);
    font-weight: 500;
    font-size: calc(var(--fs-h4) - 4px);
    color: var(--color-text-primary);
    text-align: center;
    margin-top: 8px;
}

.pricingForm__text {
    font-family: var(--font-primary);
    font-weight: 500;
    font-size: calc(var(--fs-body) - 2px);
    color: var(--color-text-tertiary);
    text-align: center;
    margin: 8px 0;
}

.pricingForm__text--noContent {
    color: var(--color-text-primary);
}

.pricingForm__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 8px 16px;
    border-radius: var(--border-radius-sm);
    background-color: var(--color-primary-dark);
    margin: 8px 0;
}

.pricingForm__button {
    margin-top: 16px;
}