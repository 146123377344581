.TableHeader-container{
   display: flex;
   flex-direction: column;
   gap: 10px;
}

.TableHeader--title{
   font-size: var(--fs-h4);
   color: var(--color-text-secondary);
}

.TableHeader--group{
   width: 100%;
   display: flex;
   justify-content: flex-end;
}

.TableHeader-create-button {
   margin-left: 16px;
}