.AcaStudentExamAccordion--name{
   font-size: var(--fs-body);
}

.AcaStudentExamAccordion-result{
   font-size: var(--fs-tiny);
   width: max-content;
   padding: 2px 12px;
   text-transform: uppercase;
   border-radius: var(--border-radius-md);
   font-weight: 900;
}

.AcaStudentExamAccordion-result--failed{
   background-color: rgba(238, 154, 154, 0.212);
   color: #b60000;
}


.AcaStudentExamAccordion-result--success{
   background-color: rgba(202, 242, 194, 0.336);
   color: #094d09;
}

.AcaStudentExamAccordion-result--pending{
   color: #c5961e;
   background-color: #ffcb4748;
}

.AcaStudentExamAccordion--date{
   font-size: var(--fs-body);
}