.academicValorationsComments-mainDiv {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.academicValorationsComments-headerDiv {
  display: flex;
}

.academicValorationsComments-titlesDiv {
  display: flex;
  flex-direction: column;
}

.academicValorationsComments-header {
  font-size: var(--fs-h3);
}

.academicValorationsComments-subtitleBadge {
  font-size: var(--fs-small) !important;
  font-weight: 500 !important;
  word-break: break-word !important;
  white-space: pre-wrap !important;
  padding: 12px !important;
  margin: 8px 0;
  width: 100% !important;
}

.academicValorationsComments-buttonRoot {
  display: flex;
  margin-left: auto;
}

.academicValorationsContainer-noContent-header{
  font-size: var(--fs-body);
}

.academicValorationsComments-accordionControl-div {
  display: flex;
  align-items: center;
  gap: 16px;
}

.academicValorationsComments-accordion-control {
  margin: 16px 0 0 0;
  box-shadow: var(--box-shadow-strong);
  border-radius: var(--border-radius-sm);
  font-size: var(--fs-small) !important;
  line-height: calc(var(--fs-small) * 1.6) !important;
}

.academicValorationsComments-accordion-panel {
  font-size: var(--fs-small) !important;
  line-height: calc(var(--fs-tiny) * 1.8) !important;
}

@media (max-width: 768px) {
  .academicValorationsComments-headerDiv {
    display: flex;
    flex-direction: column-reverse;
  }
}
