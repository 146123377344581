.LoginPage-container{
   width: 100%;
   height: 100%;
   min-height: 100dvh;

   display: flex;
   align-items: center;
   padding: 0 10%;

   background-position: center;
   background-repeat: no-repeat;
   background-size: cover;
   box-shadow: var(--box-shadow);

}

.LoginPage-card-container{
   width: 100%;
   max-width: 400px;
   height: 500px;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: center;
   gap: 50px;
   padding: 20px ;
   background-color: var(--color-base-light);
   border-radius: var(--border-radius-md);
   box-shadow: var(--box-shadow);

}

.LoginPage-card-header{
   display: flex;
   flex-direction: column;
   gap: 10px;
   align-items: center;

}

.LoginPage--title{
   font-size: var(--fs-h3);
   text-wrap: balance;
   text-align: center;
   color: var(--color-text-secondary);
}

.LoginPage--logo-img{
   height: 72px;
   width: auto;
   object-fit: cover;
}

.LoginPage-card-gif-container{
   width: 100%;
   display: grid;
   place-items: center;
}

.LoginPage-card--gif{
   max-height: 200px;
   height:100%;
   width: auto;
   object-fit: cover;
}

@media (max-width: 768px) {

   .LoginPage-container{
      padding: 5%;
   }

   .LoginPage-card-container{
      margin: 0 auto;
   }

   .LoginPage--logo-img{
      height: auto;
      width: 100%;
      object-fit: cover;
   }

   .LoginPage--title{
      font-size: var(--fs-h4);
   }
}