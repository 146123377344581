.ViewPage-section-container {
   height: 100%;
   min-height: 100dvh;
   width: 100%;
   display: flex;
   flex-direction: column;
   gap: 16px;
   padding: 0 var(--layout-padding-x);
}

.ViewPage-header-container{
   display: flex;
   flex-direction: column;
   gap: 10px;  
}

.ViewPage-header--title{
   font-size: var(--fs-h1);
   color: var(--color-text-secondary);
}

.ViewPage-header--description{
   font-size: var(--fs-body);
   color: var(--color-text-primary);
}