.NewsCard__wrapper {
   width: 100%;
   max-width: 1100px;
   height: 100%;
   margin: 0 auto;

   border-radius: var(--border-radius-lg);
   overflow: hidden;
   filter: grayscale(50%);
   background-size: cover;
   transition: all 0.3s ease;
}

.NewsCard__wrapper--true {
   opacity: 1;
}

.NewsCard__wrapper--false {
   opacity: 0.7;
   transform: scale(0.9);
}

.NewsCard__container {
   height: 100%;
   width: 100%;
   min-height: 400px;
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   align-items: flex-start;
   padding: 48px;
   box-shadow: 0 200px 88px 0 rgba(0, 0, 0, 0.75) inset;
}

.NewsCard__title {
   font-family: var(--font-primary);
   font-size: var(--fs-h2);
   line-height: var(--fs-h2);
   font-weight: 700;
   color: var(--color-white);
   max-width: 80%;
   margin-bottom: 16px;
}

.NewsCard__text {
   font-family: var(--font-primary);
   font-size: var(--fs-body);
   line-height: var(--fs-body);
   color: var(--color-white);
   max-width: 80%;
}

.NewsCard__button {
   width: 100%;
   flex: 1;
   display: flex;
   justify-content: flex-end;
   align-items: flex-end;
}