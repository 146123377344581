.MktgNewsCreatorForm-container {
   width: 100%;
   box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.25);
   border-top-left-radius: var(--border-radius-md);
   border-top-right-radius: var(--border-radius-md);

}

.MktgNewsCreatorForm-wrapper {
   padding: 20px;
   width: 100%;
   display: grid;
   grid-template-columns: 1fr 1fr;
   gap: 20px;
}

@media (max-width: 768px) {
   .MktgNewsCreatorForm-wrapper {
      grid-template-columns: 1fr;
   }
}