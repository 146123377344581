.deleteConfirmationModal__title {
    font-size: var(--fs-h3);
    line-height: calc(var(--fs-h3) * 1.2);
    color: var(--color-text-secondary);
    font-weight: 600;
    text-transform: capitalize;
    text-align: center;
}

.deleteConfirmationModal__buttons {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 16px;
    margin-top: 24px;
}