.helpBody-accordion-div{
    margin: 40px;
    width: 100%;
}

.helpBody-accordion-control{
    margin: 16px 0 0 0;
    box-shadow: var(--box-shadow-strong);
    border-radius: var(--border-radius-sm);
    font-size: var(--fs-small) !important;
    line-height: calc(var(--fs-small) * 1.6)  !important;
}

.helpBody-accordion-panel{
    font-size: var(--fs-tiny) !important;
    line-height: calc(var(--fs-tiny) * 1.8)  !important;
}