.TableNoContet-container{
   width: 100%;
   min-height: 200px;
   background-color: var(--color-base-dark);
   border-radius: var(--border-radius-md);
   display: grid;
   place-items: center;
}

.TableNoContet--text{
   font-size: var(--fs-h4);
   color: var(--color-text-secondary);
   font-weight: 900;
}