.sidebarFooter-text {
    margin-left: 8px;
  }
  
  .sidebarFooter-button-root {
    color: white !important;
  }
  
  .sidebarFooter-button-root:hover {
    border-radius: var(--border-radius-sm);
  }