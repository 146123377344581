.ModerationHome-main-container{
   width: 100%;
   display: flex;
   flex-direction: column;
   gap: 32px;
}

.ModerationHome-grid-container {
   width: 100%;
   display: grid;
   grid-template-columns: repeat(auto-fit,
         minmax(350px, 1fr));
   gap: 20px;
}

@media (max-width: 768px) {
   .ModerationHome-grid-container {
      grid-template-columns: repeat(auto-fit,
            minmax(275px, 1fr));
   }
}