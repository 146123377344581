.dailyRecomendationCard--mainContainer{
   width: 100%;
   max-width: 400px;
}
.dailyRecomendationCard-header{
    font-size: var(--fs-h3);
    margin: 16px 0 16px 0;
    color: var(--color-text-secondary);
  }
  
  .dailyRecomendationCard-testimonial-card {
    width: 100%;
    display: flex;
    align-items: center;
    background-color: var(--color-white);
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius-sm);
    color: var(--color-text-primary);
  }

  .dailyRecomendationCard-testimonial-image {
    height: 104px;
    width: auto;
    max-width: 20%;
    margin: 16px 0 16px 16px;
    border-radius: var(--border-radius-sm);
    object-fit: cover;
  }

  .dailyRecomendationCard-testimonial-details{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 16px;
  }

  .dailyRecomendationCard-recomendation{
    font-size: var(--fs-small);
    /* padding: 16px; */
  }

  .dailyRecomendationCard-author {
    font-weight: bold;
    font-size: var(--fs-small);
  }