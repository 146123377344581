.tabButton__container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    padding: 8px 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    min-height: 80px;
}

.tabButton__container--active {
    background-color: var(--color-primary-lightest);
    border-radius: var(--border-radius-md) var(--border-radius-md) 0 0;
}

.tabButton__container:hover {
    background-color: var(--color-primary-lightest);
    border-radius: var(--border-radius-md) var(--border-radius-md) 0 0;
}

.tabButton__icon {
    color: var(--color-secondary);
    height: 24px;
    width: auto;
    margin-top: auto;
}

.tabButton__text {
    font-size: var(--fs-small);
    line-height: var(--fs-small);
    color: var(--color-text-primary);
    max-width: 60%;
    text-align: center;
    margin-bottom: auto;
}