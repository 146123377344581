.AdministrativePaymentModal--title{
   font-size: var(--fs-h4);
   color: var(--color-text-secondary);
   font-weight: 9090;
}

.AdministrativePaymentModal--itemTitle{
   font-size: var(--fs-h5);
   font-weight: 400;
   color: var(--color-text-secondary);
}

.AdministrativePaymentModal--itemText{
   font-size: var(--fs-body);
   color: var(--color-text-primary);
}