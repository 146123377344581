.ModerationStructuresForm-lesson-container{
   padding: 20px;
   border-radius: var(--border-radius-md);
}

.ModerationStructuresForm--academicName{
   font-size: var(--fs-h3);
   font-weight: 400;
   color: var(--color-text-secondary);
   width: max-content;
   border-bottom: 1px solid var(--color-text-secondary);
}

.ModerationStructuresForm-academicName--icon{
   background-color: var(--color-text-secondary);
   color: var(--color-white);
   border-radius: var(--border-radius-md);
   height: 40px;
   width: 40px;
   flex-shrink: 0;
   padding: 10px;
}

.ModerationStructuresForm--moduleTitle{
   max-width: max-content;
   padding: 5px 10px;
   font-size: var(--fs-h4);
   font-weight: 700;
   color: var(--color-text-secondary);
   /* color: var(--color-white); */
   /* background-color: var(--color-text-secondary); */
   /* border-radius: var(--border-radius-md); */

}

.ModerationStructuresForm--bodyText{
   color: var(--color-text-primary);
   font-size: var(--fs-body);
   font-weight: 400;
}

.ModerationStructuresForm--lessonName{
   font-weight: 700;
   font-size: var(--fs-h5);
}

.ModerationStructuresForm--zoomLink{
   max-width: max-content;
   word-break: break-all;
   /* white-space: nowrap;
   text-overflow: ellipsis;
   overflow: hidden; */
}

.ModerationStructuresForm--onEditIcon:hover{
   cursor: pointer;
   opacity: .7;
}

.ModerationStructuresForm-lesson-container:nth-child(odd){
   background-color: var(--color-primary-lightest);
}

.ModerationStructuresForm-lesson-container:nth-child(even){
   background-color:#d1dbdf;
}


