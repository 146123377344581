.AcaTeachersTable-container{
   display: flex;
   flex-grow: 1;
}

.AcaTeachersTable-container .contentCreator__editor{
   border-bottom-left-radius: var(--border-radius-md);
   border-bottom-right-radius: var(--border-radius-md);
}

.AcademicTeacherTable-preview--label{
   font-size: var(--fs-tiny);
   color: var(--color-text-primary);
}