.salesCardPriceInfo-wrapper{
    display: flex;
    gap: 32px;
    width: 100%;
    align-items: center;
}

@media (max-width: 768px) {
    .salesCardPriceInfo-wrapper{
        flex-direction: column;
    }
}