.AdministrativeView-layout--container .contentCreator__editor{
   box-shadow: none;
   border-top-left-radius: 0;
   border-top-right-radius: 0;
   padding: 0;
}

.cell-modal-text{
   color: var(--color-text-primary);
   font-size: var(--fs-body);
}