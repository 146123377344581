.teachersTable-mainDiv {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  height: 100%;
}

.teacherTable-headerDiv {
  display: flex;
}

.teachersTable-buttonRoot {
  display: flex;
  margin-left: auto;
}

.teachersTable-mainHeader {
  font-size: var(--fs-h3);
}

.teachersTable-headerQuantity-acaUnitName {
  margin: 8px 0;
}

.teachersTable-headerQuantity-acaUnitName-badge {
  font-size: var(--fs-h6) !important;
  font-weight: 500 !important;
  word-break: break-word !important;
  white-space: pre-wrap !important;
  padding: 12px !important;
  width: 100% !important;
}

.teachersTable-headerQuantity {
  font-size: var(--fs-h6);
}

.teacherTable-titlesDiv {
  display: flex;
  flex-direction: column;
}

.teachersTable-divBadge {
  display: flex;
  justify-content: center;
  width: 100%;
}

.teachersTable-greenBadge {
  background-color: var(--color-badge-green) !important;
  font-weight: 500 !important;
  font-size: var(--fs-body) !important;
}

.teachersTable-yellowBadge {
  background-color: var(--color-badge-yellow) !important;
  font-weight: 500 !important;
  font-size: var(--fs-body) !important;
}

.teachersTable-redBadge {
  background-color: var(--color-badge-red) !important;
  font-weight: 500 !important;
  font-size: var(--fs-body) !important;
}

.teachersTable-orangeBadge {
  background-color: var(--color-badge-orange) !important;
  font-weight: 500 !important;
  font-size: var(--fs-body) !important;
}

@media (max-width: 768px) {
  .teacherTable-headerDiv {
    display: flex;
    flex-direction: column-reverse;
  }
}
