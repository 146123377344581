.aboutUsPage-sponsor-section-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 80px;
 }
 
 .aboutUsPage-sponsor-content-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10%;
 }
 
 .aboutUsPage-sponsor-content--images-container {
    display: flex;
    width: 40%;
 }
 
 .sponsor-content-image-container {
    position: relative;
    width: 100%;
    height: 100%;
 }
 
 .sponsor-image {
    object-fit: cover;
    height: auto;
    max-height: 500px;
    width: 100%;
    border-radius: var(--border-radius-sm);
 }
 
 .sponsor-logo-image {
    position: absolute;
    right: 0;
    top: 20px;
    transform: translateX(50%);
    box-shadow: var(--box-shadow);
    border-radius: 50%;
    height: 190px;
    width: 190px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 80%;
    background-color: var(--color-white);
    filter: grayscale(100%);
    transition: all .3s;
 }
 
 .sponsor-content-image-container:hover .sponsor-logo-image{
    filter: grayscale(0%);
    transition: all .3s;
 }
 
 .aboutUsPage-sponsor-content--text-container {
    display: flex;
    flex-direction: column;
    width: 40%;
    gap: 30px;
    font-size: var(--fs-body);
 }
 
 .aboutUsPage-sponsor-content-title{
    font-size: var(--fs-h3);
 }
 
 
 .aboutUsPage-sponsor-content--text-container p {
    color: var(--color-text-primary);
 }
 
 @media screen and (min-width: 769px) and (max-width: 1800px) {
 
    .aboutUsPage-sponsor-section-container {
       gap: 40px;
    }
 
    .aboutUsPage-sponsor-content--images-container {
       display: flex;
       width: 100%;
    }
    .sponsor-image {
       height: auto;
       width: 100%;
    }
 
    .sponsor-logo-image{
       height: 150px;
       width: 150px;
       right: 0;
       top: 0;
       left: 0;
       margin: auto;
       transform: unset;
       bottom: -100%;
    }
    .aboutUsPage-sponsor-content--text-container {
       width: 100%;
    }
 }
 
 @media screen and (max-width: 768px) {
 
    .aboutUsPage-sponsor-section-container {
       gap: 50px;
    }
 
    .aboutUsPage-sponsor-content-container{
       flex-direction: column;
       gap: 150px;
    }
 
    .aboutUsPage-sponsor-content--images-container{
       width: 100%;
    }
 
    .sponsor-content-image-container{
       width: 100%;
       display: flex;
       justify-content: center;
       align-items: center;
    }
 
    .sponsor-image {
       height: auto;
       width: 100%;
       max-width: 480px;
    }
 
    .sponsor-logo-image {
       right: 0;
       top: 0;
       left: 0;
       margin: auto;
       transform: unset;
       bottom: -100%;
       max-width: 190px;
       max-height: 190px;
    }
 
 
    .aboutUsPage-sponsor-content--text-container{
       text-align: center;
       width: 100%;
    }
 
    .aboutUsPage-sponsor-content--text-container .sectionTitle-container{
       align-items: center;
    }
 
 }