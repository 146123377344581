.AcademicView-studentModal--title{
   font-size: var(--fs-h4) !important;
   color: #2C4B58 !important;
   font-weight: bold !important;
}

.AcademicView-studentModal--notContent{
   font-size: var(--fs-body);
   color: var(--color-text-primary);
}

/* column on student table  */
.AcademicView-studentTable--iconContainer{
   display: grid;
   place-items: center;
   cursor: pointer;
}

.AcademicView-studentTable--iconContainer:first-child:hover{
   opacity: .8;
}

.cell-pointer{
   cursor: pointer;
}

.cell-pointer:hover{
   opacity: .8;
   text-decoration: underline;
}