.AdministrativeExpensesView__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    width: 100%;
    height: 100%;
}

.AdministrativeExpensesView__filters {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 16px;
}

.AdministrativeExpensesView__cell--center {
    display: grid;
    place-items: center;
}

.AdministrativeExpensesView__cell {
    cursor: pointer;
}

.AdministrativeExpensesView__cell:hover{
    opacity: .8;
    text-decoration: underline;
}

.AdministrativeExpensesView__ul {
    margin-bottom: 8px;
    margin-left: 16px;
}
.AdministrativeExpensesView__ul:last-child {
    margin-bottom: 0;
}