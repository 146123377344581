.sectionTitle-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
    width: 100%;
    z-index: 1;
 }
 
 .sectionTitle-upText {
    font-size: var(--fs-h6);
    color: var(--color-text-primary);
    background-color: var(--color-tertiary);
    border-radius: var(--border-radius-sm);
    padding: 6px 12px;
    box-shadow: var(--box-shadow);
    text-transform: uppercase;
    font-weight: 600;
    line-height: var(--fs-h6);
 
 }
 
 .sectionTitle-downText {
    font-size: var(--fs-h2);
    font-weight: 700;
    line-height: var(--fs-h2);
    color: var(--color-text-primary);
 }