.TanStackTable-first-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.TanStackTable-wrapper-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
}

.TanStackTable-TableContent-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow-x: auto;
    /* probar esto para el overflow */
    /*    overflow-x: auto;
       max-width: calc(var(--maxWidthContainer) - var(--sidebar-width) - var(--layout-padding-x) - var(--layout-padding-x)); */
}

@media (max-width: 768px) {

    /* pagination */
    .Table-pagination--container {
        justify-content: center;
    }

    .Table-button--text {
        display: none;
    }
}