.notificationBanner {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    border-radius: 3px;
}

.notificationBanner__information {
    background-color: var(--color-blue--light);
}

.notificationBanner__warning {
    background-color: var(--color-yellow--light);
}

.notificationBanner__error {
    background-color: var(--color-red--light);
}

.notificationBanner__success {
    background-color: var(--color-green--light);
}

.notificationBanner__icon {
    margin-right: 8px;
    color: var(--color-text-primary);
}

.notificationBanner__text {
    color: var(--color-text-primary);
    font-size: var(--fs-body);
    line-height: calc(var(--fs-body) * 1.2);
}