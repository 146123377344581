.ModerationDashboard-main-container .AdministrativeDashboard-card-morePopular,
.ModerationDashboard-main-container .AdministrativeDashboard-card-morePopular .AdministrativeDashboard-stack-wrapper {
   max-height: max-content;
}

.ModDashboard-TeacherRow--container {
   border-radius: var(--border-radius-lg);
   cursor: pointer;
}

.ModDashboard-TeacherRow--container:hover {
   opacity: .9;
}

.ModDashboard-TeacherRow--container:nth-child(odd) {
   background-color: var(--color-base);
}

.ModDashboard-TeacherRow--container:nth-child(even) {
   background-color: var(--color-base-dark);
}

.ModDashboard-text-small {
   font-size: var(--fs-small);
   color: var(--color-text-primary);
}

.ModDashboard-text-tiny {
   font-size: var(--fs-tiny);
   color: var(--color-text-primary);
}

.ModDashboard-text-tiny-secondary {
   font-size: var(--fs-tiny);
   color: var(--color-text-secondary);
}