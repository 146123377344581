.salesDashboardProfitCommissions-root {
  background-color: var(--color-primary-dark) ;
  padding: var(--mantine-spacing-xl);
  border-radius: var(--border-radius-md);
  display: flex;
  width: fit-content;
}
.salesDashboardProfitCommissions-month {
  font-size: var(--fs-body);
  color: var(--color-white);
  line-height: calc(var(--fs-body)*1.4);
  text-align: center;
}

.salesDashboardProfitCommissions-controls {
  display: flex;
  flex-direction: column;
  width: 100px;
  margin-right: calc(var(--mantine-spacing-xl) * 2);
}

.salesDashboardProfitCommissions-date {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.salesDashboardProfitCommissions-control {
  height: rem(28px);
  width: 100%;
  color: var(--color-white) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--border-radius-sm);
  transition: background-color 50ms ease;
}

.salesDashboardProfitCommissions-control:hover {
  background-color: var(--color-secondary-transparent);
  color: var(--color-white);
}

.salesDashboardProfitCommissions-iconStyle{
    font-size: var(--fs-body);
    line-height: calc(var(--fs-body)*1.4)
}

.salesDashboardProfitCommissions-value-style{
    display: flex;
    justify-content: center;
    font-size: var(--fs-body);
}

.salesDashboardProfitCommissions-value-iconCashStyle{
    margin-right: 8px;
}

@media (max-width: 768px) {
  .salesDashboardProfitCommissions-control-icon {
    transform: rotate(-90deg);
  }

  .salesDashboardProfitCommissions-control {
    height: rem(34px);
    width: rem(34px);
  }

  .salesDashboardProfitCommissions-controls {
    flex-direction: row;
    align-items: center;
    margin-right: 0;
    margin-bottom: var(--mantine-spacing-xl);
  }

  .salesDashboardProfitCommissions-root {
    flex-direction: column;
  }
}
