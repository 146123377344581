.createModule__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 8px;
}

.createModule__title {
    font-family: var(--font-primary);
    font-weight: 700;
    font-size: var(--fs-h3);
    color: var(--color-text-primary);
    text-align: center;
    margin-bottom: 24px;
}

.createModule__form {
    width: 100%;
    display: grid;
    align-items: center;
}

.createModule__button {
    margin-top: 24px;
}