.mktgCalendar__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    height: 100%;
}

.mktgCalendar__button {
    margin-bottom: 24px;
    align-self: flex-end;
}
