.AcaStudentCertificatesAccordion--container{
   display: flex;
   flex-direction: column;
   width: 100%;
}

.AcaStudentCertificatesAccordion--certificateRow{
   border-top: 1px solid #8BA7B2;
   padding: 10px 0;
}

.AcaStudentCertificatesAccordion--title{
   font-size: var(--fs-body);
   color: #2C4B58;
   font-weight: normal;
   text-align: center;
}

.AcaStudentCertificatesAccordion--downloadIcon{
   color: #5D5A6F;
   cursor: pointer;
}

.AcaStudentCertificatesAccordion--downloadIcon:hover{
   opacity: .8;
}

.AcaStudentCertificatesAccordion--note{
   background-color: #DF3576;
   color: #fff;
   font-size: var(--fs-tiny);
   border-radius: var(--border-radius-md);
   padding: 2px 8px;
}