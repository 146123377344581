.discounts__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    max-width: 100%;
    height: 100%;
}

.salesDiscounts__preview {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.salesDiscounts__preview__icon {
    height: 100%;
    margin-left: 16px;
    cursor: pointer;
}
.salesDiscounts__preview__icon:hover {
    color: var(--color-primary-light);
}

.salesDiscounts__badgeDiv{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.salesDiscounts__badgeRoot__red {
    background-color: var(--color-badge-red) !important;
}
.salesDiscounts__badgeRoot__green {
    background-color: var( --color-badge-green) !important;
}