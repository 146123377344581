.salesBulkMessagesPreview__container {
    display: flex;
    flex-direction: column;
    width: auto;
    height: auto;
}

.salesBulkMessagesPreview__title {
    font-family: var(--font-primary);
    font-size: var(--fs-h3);
    line-height: var(--fs-h3);
    font-weight: 500;
    color: var(--color-text-secondary);
    margin-bottom: 16px;
    text-align: center;
}

.salesBulkMessagesPreview__noData {
    font-family: var(--font-primary);
    font-size: var(--fs-body);
    line-height: var(--fs-body);
    font-weight: 500;
    color: var(--color-text-primary);
    text-align: center;
}