/* pagination */
.TablePagination-container {
   display: flex;
   width: 100%;
   align-items: center;
   gap: 10px;
}

.TablePagination-button--text {
   font-size: var(--fs-small);
}

.tablePaginationItems__select {
   width: 88px;
}

@media (max-width: 768px) {
   .TablePagination-container{
      justify-content: center;
   }
   .TablePagination-button--text {
      display: none;
   }
}