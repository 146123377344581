.contentCreator__container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
}

.contentCreator__heading {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    height: auto;
    flex-wrap: wrap;
}

.contentCreator__title {
    font-size: var(--fs-h3);
    color: var(--color-text-secondary);
}

.contentCreator__preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
    height: auto;
    flex-grow: 1;
}

.contentCreator__editor {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    padding: 16px 32px;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.25);
    border-top-left-radius: var(--border-radius-md);
    border-top-right-radius: var(--border-radius-md);
}

.contentCreator__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.contentCreator__form__button {
    /*width: 100%;*/
    margin-top: 16px;
}