.salesPayments__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  height: 100%;
}

.salesPayments-iconReceipt {
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
}
.salesPayments-iconReceipt:hover {
  color: var(--color-tertiary-light);
}



.salesPayment-confirmation-icon {
  color: var(--color-green);
  cursor: pointer;
}
.salesPayment-confirmation-icon:hover {
  color: var(--color-green-dark);
}

.salesPayment-denied-icon {
  color: var(--color-red);
  cursor: pointer;
}
.salesPayment-denied-icon:hover {
  color: var(--color-red-dark);
}

.salesPayment-cell-center {
  display: grid;
  place-items: center;
}

.salesPayment-studentData-span {
  cursor: pointer;
}
.salesPayment-studentData-span:hover {
  text-decoration: underline;
}

.salesPayments-modalContent {
  background-color: #3d6271 !important;
}

.salesPayments-modalTitle {
  display: flex;
  width: 100%;
  font-size: var(--fs-h4) !important;
  background-color: #3d6271 !important;
  color: #fff;
}

.salesPayments-modalHeader {
  background-color: #3d6271 !important;
}

.salesPayments-modalClose {
  color: #fff !important;
}

.salesPayments-modal-fatherDiv {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.salesPayments-userData-tags {
  display: flex;
  color: #fff;
  gap: 8px;
  font-size: var(--fs-small) !important;
  line-height: calc(var(--fs-small) * 1.3) !important;
}

.salesPayment__badge__container {
  display: grid;
  place-items: center;
}