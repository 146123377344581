.eventCalendarForm__container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
}

.eventCalendarForm__preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
    height: auto;
    flex-grow: 1;
}

.eventCalendarForm__editor {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    box-shadow: 0 -8px 24px 0 rgba(0,0,0,0.25);
    padding: 16px 32px;
}

.eventCalendarForm__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.eventCalendarForm__form__button {
    /*width: 100%;*/
    margin-top: 16px;
}

.SalesPaymentForm-iamgeLink-div{
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 8px;
    margin-top: 8px;
    border-radius: var(--border-radius-sm);
    background-color: var(--color-base-dark);
    box-shadow: var(--box-shadow-strong);
}

.SalesPaymentForm-pill-container {
    display: grid;
    grid-template-columns: 90% 10%;
    gap: 8px;
    width: 100%;
}

.SalesPaymentForm-image-header{
    font-size: var(--fs-small);
    line-height:calc(var(--fs-h6)*1.4);
}

.SalesPaymentForm-installments-grid-wrapper {
    background-color: var(--color-primary-lightest);
    /*width: 100%;*/
    height: auto;
    padding: 16px 8px;
    /*margin: 0;*/
}

.SalesPaymentForm-installments-grid-container {
    display: grid;
    place-items: center;
}
.SalesPaymentForm-installments-grid {
    width: 95%;
    align-self: center;
    background-color: var(--color-primary-lightest);
    border-radius: var(--border-radius-sm);
    padding: 4px 16px 8px;
}

.salesPaymentForm-typeSelect{
    display: flex;
    margin-right: auto;
    max-width: 250px;
}

.salesPaymentForm-button-container {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
}

.salesPaymentForm-switch-grid {
    display: grid;
    place-items: center;
    width: auto;
    margin-top: 24px;
}