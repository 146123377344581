.lessonForm__container {
    height: 100vh;/*TODO: Fix*/
    max-height: 100vh;
    width: 100%;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: minmax(0, 40%) minmax(0, 50%);
    justify-items: center;
    gap: 24px;
}

.lessonForm__preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
    height: auto;
}

.lessonForm__editor {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    box-shadow: 0 -8px 24px 0 rgba(0,0,0,0.25);
    /*grid-row: 2;*/
}

.lessonForm__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0 32px;
}

.lessonForm__form__button {
    /*width: 100%;*/
    margin-top: 16px;
}