.lessonForm__module__wrapper {
    width: 100%;
    height: 100%;
    padding: 16px 0;
    margin: 8px 0;
}

.lessonForm__tab {
    max-width: 100px;
    overflow: hidden;
    padding: 8px !important;
}

.lessonForm__panel {
    width: 100%;
    padding: 0 16px;
}

.lessonForm__tabLabel {
    color: var(--color-text-primary);
}
.lessonForm__tabLabel:hover {
    color: var(--color-text-tertiary);
}

.lessonForm__module__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0;
    padding: 16px;
    border-radius: var(--border-radius-md);
    border: 1px solid var(--color-primary);
    /*background-color: var(--color-primary-dark);*/
}

.lessonForm__subtitle {
    font-family: var(--font-primary);
    font-weight: 500;
    font-size: var(--fs-body);
    color: var(--color-text-primary);
    text-align: center;
    margin-bottom: 8px;
}

.lessonForm__subtitle--alternative {
    font-family: var(--font-primary);
    font-weight: 500;
    font-size: var(--fs-body);
    color: var(--color-text-tertiary);
    text-align: center;
    margin-bottom: 8px;
}


.lessonForm__button {
    margin-top: 16px;
    width: 100%;
    white-space: normal;
}

.lessonForm__button--create {
    margin: 0 8px;
}

.lessonForm__lesson__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 24px 0 8px;
}


.lessonForm__lesson__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0 4px;
    margin: 0;
    border-radius: var(--border-radius-md);
}

.lessonForm__lessonIcon {
    cursor: pointer;
    color: var(--color-tertiary);
    stroke-width: 1px;
}

.lessonForm__iconTrash {
    cursor: pointer;
    color: var(--color-text-primary);
    margin-top: 20px;
}

.lessonForm__iconTrash:hover {
    color: var(--color-text-secondary);
}
