.sidebarLinksGroup-control {
    display: flex;
    width: 100%;
    padding: var(--mantine-spacing-xs) var(--mantine-spacing-md);
    color: var(--mantine-color-text);
    font-size: var(--mantine-font-size-sm);
}

.sidebarLinksGroup-innerLinks {
    font-size: var(--fs-small);
    text-decoration: none;
    color: black;
}

.sidebarLinksGroup-labels {
    font-size: var(--fs-body);
}

.sidebarLinksGroup-chevron {
    transition: transform 1000ms ease;
}

.sidebarLinksGroup-link {
    text-decoration: none;
    color: var(--color-text-tertiary) !important;
}

/* Estilos de hover para NavLink */
.sidebarLinksGroup-link:hover {
    border-radius: var(--border-radius-sm);
    background-color: var(--color-primary-darkest) !important;
    color: var(--color-text-tertiary) !important;
}