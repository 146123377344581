
.studentModal__modalContent {
    background-color: #3d6271 !important;
}

.studentModal__modalTitle {
    display: flex;
    width: 100%;
    font-size: var(--fs-h4) !important;
    background-color: #3d6271 !important;
    color: #fff;
}

.studentModal__modalHeader {
    background-color: #3d6271 !important;
}

.studentModal__modalClose {
    color: #fff !important;
}

.studentModal__modal__fatherDiv {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.studentModal__userData__tags {
    display: flex;
    color: #fff;
    gap: 8px;
    font-size: var(--fs-small) !important;
    line-height: calc(var(--fs-small) * 1.3) !important;
}

.studentModal__userData__link {
    text-decoration: underline;
    cursor: pointer;
}