.cell-modal--title{
   color: var(--color-text-secondary) !important;
   text-transform: uppercase !important;
   font-size: var(--fs-body) !important;
   font-weight: bold !important;
}

.cell-modal--installmentsAccordionTitle{
   font-size: var(--fs-body);
   color: var(--color-text-primary);
}

.administrativePayment-cell-center {
   display: grid;
   place-items: center;
}