.QuestionnaireForm-question-container {
   width: 100%;
   display: grid;
   grid-template-columns: 1fr max-content max-content;
   grid-template-areas:
      "label-question label-answer label-addButton"
      "question answer addButton"
   ;
   gap: 0px 10px;
   align-items: center;
   padding: 10px 0;
}

.QuestionnaireForm--questionTitle{
   font-size: var(--fs-h3);
}

/* .QuestionnaireForm-questionOrder-container{
   border-radius: var(--border-radius-md);
   outline: 1px solid var(--color-text-primary);
   padding: 10px;
} */

.QuestionnaireForm-questionOrder--text{
   font-size: var(--fs-body);
   color: var(--color-text-primary);
}

.QuestionnaireForm-labelQuestion-container {
   grid-area: label-question;
   width: 100%;
}

.QuestionnaireForm-labelAnswer-container {
   grid-area: label-answer;
   width: 100%;
}

.QuestionnaireForm-labelAddButton-container {
   grid-area: label-addButton;
   width: 100%;
}

.QuestionnaireForm-question--label {
   font-size: var(--fs-small);
   color: var(--color-text-primary);
}

.QuestionnaireForm--question-input {
   grid-area: question;
   width: 100%;
}

.QuestionnaireForm--answer-input {
   grid-area: answer;
   width: 100%;
   display: grid;
   place-items: center;
}

.QuestionnaireForm-labelAddButton-input {
   grid-area: addButton;
   width: 100%;
   display: grid;
   place-items: center;
}

.QuestionnaireForm--modalText{
   font-size: var(--fs-body);
   color: var(--color-text-secondary);
   text-align: center;
   text-wrap: balance;
}

@media (max-width: 768px) {
   .QuestionnaireForm-question-container {
      grid-template-columns: 1fr 1fr;
      grid-template-areas:
         "label-question label-question"
         "question question"
         "label-answer label-addButton"
         "answer addButton" 
      ;
      padding: 30px 0px;
      border-bottom: 1px solid var(--color-text-primary);
   }

   .QuestionnaireForm-labelAddButton-container, .QuestionnaireForm-labelAnswer-container{
      text-align: center;
   }
   .QuestionnaireForm--answer-input, .QuestionnaireForm-labelAddButton-input {
      display: flex;
      justify-content: center;
   }

}