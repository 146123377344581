.academicUnits__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    max-width: 100%;
    height: 100%;
}

.academicUnits__button {
    margin-bottom: 24px;
    align-self: flex-end;
}

.academicUnits__icon {
    width: 100%;
    justify-self: center;
    cursor: pointer;
}

.acaUnit-endDate-container {
    display: flex;
    justify-content: center;
}

.acaUnit--invalidDate {
    display: flex;
    justify-content: center;
}

.acaUnit-colorReferences {
    display: flex;
    padding: 4px 0 16px 0;
    gap: 8px;
}

.acaUnit-colorReferences-span {
    display: flex;
}

.acaUnit-colorReference-p-Styling {
    display: flex;
    align-items: center;
    font-size: var(--fs-tiny);
    line-height: calc(var(--fs-tiny) * 1.2)
}

.acaUnit-colorReferences-IconGreen {
    color: var(--color-badge-green);
}

.acaUnit-colorReferences-IconYellow {
    color: var(--color-badge-yellow);
}

.acaUnit-colorReferences-IconRed {
    color: var(--color-badge-red);
}

.actionButtonsAcademicUnit--iconBtn{
   cursor: pointer;
}

.actionButtonsAcademicUnit--iconBtn:hover{
   opacity: .8;
}

.closeCampusModal-title{
   font-size: var(--fs-h3);
   color: var(--color-text-secondary);
   text-align: center;
}

.closeCampusModal-description{
   font-size: var(--fs-body);
   color: var(--color-text-secondary);
   text-align: center;
}

.actionButtonsAcademicUnit--iconBtn{
   cursor: pointer;
}

.actionButtonsAcademicUnit--iconBtn:hover{
   opacity: .8;
}

.closeCampusModal-title{
   font-size: var(--fs-h3);
   color: var(--color-text-secondary);
   text-align: center;
}

.closeCampusModal-description{
   font-size: var(--fs-body);
   color: var(--color-text-secondary);
   text-align: center;
}