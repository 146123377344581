.salesDashboardCarousel--father-div{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 32px;
    padding: 16px;
    border-radius: var(--border-radius-sm);
    box-shadow: var(--box-shadow-strong);
}

.salesDashboardCarousel-header{
    font-size: var(--fs-h3);
    line-height: calc(var(--fs-h3)*1.3);
}

.salesDashboardCarousel-splide-div{
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.salesDashboardCarousel-splide-header{
    font-size: var(--fs-h4);
    line-height: calc(var(--fs-h4)*1.3);
}

.salesDashboardCarousel-profits-section{
    display: flex;
    flex-direction: column;
    gap: 8px;
}