.specialtiesCarousel-section-div {
  width: 100%;
}

.specialtiesCard-link-navigation {
  text-decoration: none;
}

.specialtiesCard-container-div {
  display: flex;
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
  border-radius: var(--border-radius-sm);
}

.specialtiesCard-image-custom {
  object-fit: cover;
  position: absolute;
  z-index: 1;
}

.specilatiesCard-content-container {
  position: relative;
  z-index: 2;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 24px;
  box-shadow: 0 56px 64px 0 rgba(0, 0, 0, 0.75) inset;
}

.specialtiesCard-title-for-specialties {
  color: var(--color-text-primary);
  font-size: var(--fs-h4);
  line-height: var(--fs-h4);
}
