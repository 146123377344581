.academicSpecialties__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    height: 100%;
  }
  
.acaSpecialties-description-styling{
  cursor: pointer;
}
.acaSpecialties-description-styling:hover{
  text-decoration: underline;
}

.acaSpecialties-modalTitle{
  font-size: var(--fs-h4) !important;
}

.acaSpecialties-modalBody{
  font-size: var(--fs-tiny);
}
