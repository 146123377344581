.salesHome-father-div{
   display: flex;
   flex-direction: column;
   gap: 32px;
   max-width: 1000px;
}

.salesHome-dashboard-fatherDiv{
   width: 100%;
   display: grid;
   grid-template-columns: repeat(auto-fit,
         minmax(350px, 1fr));
   gap: 20px;
}

.salesHome-grid-container {
   width: 100%;
   display: grid;
   grid-template-columns: repeat(auto-fit,
         minmax(350px, 1fr));
   gap: 20px;
}

@media (max-width: 768px) {
   .salesHome-grid-container {
      grid-template-columns: repeat(auto-fit,
            minmax(275px, 1fr));
   }
}