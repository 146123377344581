.AdministrativeDashboard-wrapper-container {
   display: flex;
   flex-direction: column;
   gap: 30px;
   width: 100%;
   padding-bottom: 100px;
}

.AdministrativeDashboard-section-container {
   width: 100%;
   display: flex;
   flex-direction: column;
   gap: 20px;
}

.AdministrativeDashboard-titleSection {
   font-size: var(--fs-h3);
   color: var(--color-text-secondary);
}

.AdministrativeDashboard-container {
   width: 100%;
   display: flex;
   gap: 20px;
}

.AdministrativeDashboard-grid {
    width: 100%;
    display: grid;
   gap: 16px;
   grid-template-areas: "thisMonth thisMonth"
                        "morePopular lessPopular";
}

.AdministrativeDashboard-card-thisMonth {
   grid-area: thisMonth;
}

.AdministrativeDashboard-card-morePopular {
    grid-area: morePopular;
}

.AdministrativeDashboard-card-lessPopular {
    grid-area: lessPopular;
}

.AdministrativeDashboard-charts {
   width: 100%;
   display: flex;
   flex-direction: column;
   gap: 16px;
}

.AdministrativeDashboard-gridContainer {
   width: 100%;
   display: grid;
   grid-template-columns: repeat(auto-fit, minmax(264px, 1fr));
   grid-auto-rows: minmax(100px, 264px);
   gap: 16px;
}

.AdministrativeDashboard-card {
   width: 100%;
   height: 100%;
   display: flex;
   flex-direction: column;
   gap: 16px;
   min-height: 100px;
   max-height: 264px;
   overflow-x: hidden;
   padding: 10px;
   background-color: var(--color-base-light);
   border-radius: var(--border-radius-md);
   box-shadow: var(--box-shadow);
}

.AdministrativeDashboard-card-title {
   font-size: var(--fs-h4);
   color: var(--color-text-primary);
}

.AdministrativeDashboard-stack-wrapper {
   overflow-y: auto;
   min-height: 100px;
   max-height: 264px;
}

/* estilizar barra de scroll */
.AdministrativeDashboard-stack-wrapper::-webkit-scrollbar {
   width: 8px;
}

.AdministrativeDashboard-stack-wrapper::-webkit-scrollbar-track {
   background: #f1f1f1;
}

.AdministrativeDashboard-stack-wrapper::-webkit-scrollbar-thumb {
   background: #888;
   border-radius: 5px;
}

.AdministrativeDashboard-card-rowItem:hover {
   background-color: var(--color-base);
}

.AdministrativeDashboard-card-bodyText {
   font-size: var(--fs-small);
   color: var(--color-text-primary);
}