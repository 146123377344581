.administrativeCommissionsModal__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    height: 100%;
}

.administrativeCommissionsModal__table {
    width: 100%;
    /*border-collapse: collapse;*/
}

.administrativeCommissionsModal__table__head {
    color: var(--color-text-secondary);
    font-size: var(--fs-body);
    line-height: calc(var(--fs-body) * 1.25);
    font-weight: 700;
    padding: 8px 12px;
    border: 3px solid var(--color-text-primary);
    background-color: var(--color-text-primary);
}
.administrativeCommissionsModal__table__row {
    color: var(--color-text-primary);
    font-size: var(--fs-body);
    line-height: calc(var(--fs-body) * 1.25);
    font-weight: 500;
    padding: 8px 12px;
    border: 1px solid var(--color-text-primary);
    text-align: center;
    vertical-align: center;
}

.administrativeCommissionsModal__text {
    margin-top: 16px;
    color: var(--color-text-primary);
    font-size: var(--fs-body);
    line-height: calc(var(--fs-body) * 1.25);
    font-weight: 500;
    text-align: center;
    width: 100%;
    font-style: italic;
}
.administrativeCommissionsModal__text span {
    font-weight: 700;
}