.ExamForm-question-container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr max-content max-content;
    grid-template-areas:
      "label-question label-answer label-addButton"
      "question answer addButton"
;
    gap: 0 10px;
    align-items: center;
    padding: 10px 0;
}

.ExamForm--questionTitle{
    font-size: var(--fs-h3);
}

.ExamForm--checkbox {
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
}

.ExamForm-labelQuestion-container {
    grid-area: label-question;
    width: 100%;
}

.ExamForm-labelAnswer-container {
    grid-area: label-answer;
    width: 100%;
}

.ExamForm-labelAddButton-container {
    grid-area: label-addButton;
    width: 100%;
}

.ExamForm-question--label {
    font-size: var(--fs-small);
    color: var(--color-text-primary);
}

.ExamForm--question-input {
    grid-area: question;
    width: 100%;
}

.ExamForm--answer-input {
    grid-area: answer;
    width: 100%;
    display: grid;
    place-items: center;
}

.ExamForm-labelAddButton-input {
    grid-area: addButton;
    width: 100%;
    display: grid;
    place-items: center;
}

.ExamForm--modalText{
    font-size: var(--fs-body);
    color: var(--color-text-secondary);
    text-align: center;
    text-wrap: balance;
}

@media (max-width: 768px) {
    .ExamForm-question-container {
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
         "label-question label-question"
         "question question"
         "label-answer label-addButton"
         "answer addButton"
    ;
        padding: 30px 0;
        border-bottom: 1px solid var(--color-text-primary);
    }

    .ExamForm-labelAddButton-container, .ExamForm-labelAnswer-container{
        text-align: center;
    }
    .ExamForm--answer-input, .ExamForm-labelAddButton-input {
        display: flex;
        justify-content: center;
    }

}