.TableContent-content-container {
    /* max-width: 100vw; */
    width: 100%;
}

.TableContent-content--thead {
    /* width: 100%; */
    text-align: left;
    font-size: var(--fs-body);
    color: var(--color-text-secondary);
    text-transform: uppercase;
}

.TableContent-content-thead--row {
    color: var(--color-white);
    background-color: var(--color-text-secondary);
}

.TableContent-content-thead--cell:hover {
    background-color: var(--color-primary);
    cursor: pointer;
}

.TableContent-content-thead--row:last-child .TableContent-content-thead--cell:last-child {
    border-top-right-radius: var(--border-radius-sm);
}

.TableContent-content-thead--row:last-child .TableContent-content-thead--cell:first-child {
    border-top-left-radius: var(--border-radius-sm);
}

.TableContent-content-thead--cell-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
}

.TableContent-thead--icon {
    margin: 3px 0 0 8px;
    cursor: pointer;
}

.TableContent--sortActive {
    background-color: var(--color-white);
    color: var(--color-primary);
    border-radius: 50%;
}

.TableContent-resizer {
    position: absolute;
    opacity: 0;
    top: 0;
    right: 0;
    height: 100%;
    width: 4px;
    background-color: var(--color-secondary);
    cursor: col-resize;
    user-select: none;
    touch-action: none;
    border-radius: 6px;
}

.TableContent-resizer.isResizing {
    background-color: var(--color-tertiary);
    opacity: 1;
}

th:hover > .TableContent-resizer {
    opacity: 1;
}

.TableContent-content--tbody {
    width: 100%;
    text-align: left;
    font-size: var(--fs-body);
    color: var(--color-text-primary);
    /* overflow-x: auto; */
}

.TableContent-content-tbody--row {
    overflow-x: auto;
}

.TableContent-content-tbody--row:nth-child(even) {
    background-color: var(--color-primary-lightest);
    color: var(--color-text-primary);
}

.TableContent-content-tbody--cell, .TableContent-content-thead--cell {
    /* max-width: 200px; */
    position: relative;
    overflow: hidden;
    padding: 4px;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.TableContent-content-tbody--row:last-child .TableContent-content-tbody--cell:last-child {
    border-bottom-right-radius: var(--border-radius-sm);
}

.TableContent-content-tbody--row:last-child .TableContent-content-tbody--cell:first-child {
    border-bottom-left-radius: var(--border-radius-sm);
}


.TableContent--actionButtons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
}

.TableContent--editBtn {
    cursor: pointer;
    color: var(--color-text-primary);
}

.TableContent--editBtn:hover {
    color: var(--color-text-secondary);
}

/*STICKY COLUMN*/

.TableContent--sticky--header {
    position: -webkit-sticky;
    position: sticky;
    background-color: var(--color-text-secondary);
    z-index: 100;
    right: 0;
   box-shadow: -6px 0 5px 0 rgba(0,0,0,0.3);
}

.TableContent--sticky--row {
    position: -webkit-sticky;
    position: sticky;
    /*background-color: white;*/
    z-index: 100;
    right: 0;
    box-shadow: -6px 0 5px 0 rgba(0,0,0,0.3);
   background-color: var(--color-base-dark);
}

/*.TableContent--sticky--row:nth-child(even) {*/
/*    background-color: var(--color-primary-lightest);*/
/*    color: var(--color-text-primary);*/
/*}*/