.ViewCard-container{
   display: flex;
   background-color: var(--color-white);
   padding: 20px;
   border-radius: var(--border-radius-md);
   box-shadow: var(--box-shadow);
   transition: all .3s ;
   color: inherit;
}

.ViewCard-container:hover{
   transition: all .3s ;
   opacity: .7;
}

.ViewCard--title{
   font-size: var(--fs-h3);
   color: var(--color-text-secondary);
}

.ViewCard--text{
   font-size: var(--fs-body);
   color: var(--color-text-primary);
}

.ViewCard--icon{
   background-color: var(--color-primary);
   border-radius: var(--border-radius-md);
   padding: 8px;
}