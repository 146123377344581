.moderationContainer__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    padding: 8px 0 0 0;
    max-width: 100%;
    height: 100%;
}

.moderationContainer-filtersContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.moderationContainer-DatePicker-root{
    width: 300px;
}

.moderationContainer-MultiSelect-root{
    width: 300px;
}

.moderationContainer-father-div{
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.moderationContainer-warning-container{
    display: flex;
    flex-direction: column;
    gap: 8px;
    background-color: #FFCB47;
    padding: 8px;
    border-radius: var(--border-radius-sm);
}

.moderationContainer-alertIcon{
    display: flex;
    align-items: center;
    width: 100%;
}

.moderationContainer-modalTitle{
    display: flex;
    justify-content: center;
    width: 100%;
    font-size: var(--fs-h3) !important;
    line-height: calc(var(--fs-h3)*1.3) !important;
}

.moderationContainer-modalText{
    font-size: var(--fs-small);
    line-height: calc(var(--fs-small)*1.3)
}


.lesson-state-tag--closed, .lesson-state-tag--open{
   font-size: var(--fs-tiny);
   padding: 4px 8px;
   color: var(--color-white);
   font-weight: 700;
   border-radius: var(--border-radius-md);
}

.lesson-state-tag--open{
   background-color: var(--color-badge-green);
}

.lesson-state-tag--closed{
   background-color: var(--color-badge-red);
}

.moderationClasses-icon {
    cursor: pointer;
}
.moderationClasses-icon:hover {
    color: var(--color-text-secondary);
}

.moderationClasses-cell-center {
    display: grid;
    place-items: center;
}
