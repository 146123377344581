.ModDashboardModal-text-small {
   font-size: var(--fs-small);
   color: #5D5A6F;
}

.ModDashboardModal-text-tiny {
   font-size: var(--fs-tiny);
   color: #5D5A6F;
}

.ModDashboardModal-text-tiny-secondary {
   font-size: var(--fs-tiny);
   color: #2C4B58;
}

.ModDashboardModal-badge {
   display: flex;
   align-items: center;
   justify-content: center;
   gap: 2px;
   border-radius: 1rem;
   padding: 2px 8px;
   color: #fff;
   font-weight: 700;
   font-size: 12px;
}

.ModDashModal-badge--secondary{
   background-color: #77BDE0;
}
.ModDashModal-badge--tertiary{
   background-color: #DF3576;
}

.ModDashboardTeacherStatsModal--line{
   height: 1px;
   width: 100%;
   background-color: #35556250;
}

.ModDashboardModal--name-title{
   text-wrap: nowrap;
   text-overflow: ellipsis;
}