.preloadedLessonsTable__editBtn {
    cursor: pointer;
    color: var(--color-text-primary);
}

.preloadedLessonsTable__editBtn:hover {
    color: var(--color-text-secondary);
}

.preloadedLessonsTable__editingRow {
    background-color: var(--color-primary-lightest-transparent);
    border-radius: var(--border-radius-sm);
}