.ModerationStructuresView-main-container{
   display: flex;
   flex-grow: 1;
   padding-bottom: 100px;
}
.ModerationStructuresView-main-container .contentCreator__container{
   flex-grow: 1;
}
.ModerationStructuresView-main-container .contentCreator__editor{
   flex-grow: 1;
   height: 100%;
   box-shadow: none;
   border-top-left-radius: 0;
   border-top-right-radius: 0;
   padding: 0;
}

.au-type-moderation--tag{
   width: 100%;
   padding: 4px 8px;
   border-radius: var(--border-radius-md);
   font-size: var(--fs-small);
   color: var(--color-white);
   font-weight: 700;
}

.au-type-tag--course{
   background-color: var(--color-tertiary);
}

.au-type-tag--diplomat{
   background-color: var(--color-primary);
}

.au-type-tag--posgrade{
   background-color: var(--color-secondary);
}

.au-type-tag--webinar{
   background-color: rgb(223, 59, 59);
}

.au-type-tag--masterclass{
   background-color: rgb(65, 131, 65);
}

.au-type-tag--workshop{
   background-color: rgb(204, 155, 64);
}

.au-type-tag--bootcamp{
   background-color: rgb(196, 103, 118);
}

