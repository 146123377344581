.cardInfo-column {
    width: 100%;
    padding-top: 0 !important;
    height: 100%;
    background-color: var(--color-primary-light) !important;
    box-shadow: var(--box-shadow);
  }
  
  .cardInfo-column .cardInfo-description-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
  }
  
  .cardInfo-description-badge-tag {
    background-color: var(--color-tertiary) !important;
  }
  
  .cardInfo-description-badge-type {
    background-color: var(--color-secondary) !important;
  }
  
  .cardInfo-column .card-info-title {
    font-size: var(--fs-h5);
    text-transform: uppercase;
    color: var(--color-text-tertiary);
  }
  
  .cardInfo-column .cardInfo-commercialInformation-container{
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: flex-start;
      width: 100%;
  }
  
  .cardInfo-commercialInformation--row{
      display: flex;
      align-items: center;
      gap: 20px;
  }
  
  .cardInfo-column .card-items-info--group {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .cardInfo-column .card-items-info--group span {
    font-size: var(--fs-small);
    color: var(--color-text-tertiary);
  }
  
  .card-items-icon {
    color: var(--color-secondary);
    flex-shrink: 0;
  }
  
  .cardInfo-column .text-overflow-ellipsis {
    text-wrap: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .cardInfo-image{
    width: 100%;
    max-width: 100%;
  }
  
  @media (max-width: 400px) {
     .cardInfo-column .cardInfo-commercialInformation-container{
        flex-direction: column;
        align-items: flex-start;
         gap: 20px
     }
  
      .cardInfo-column .cardInfo-commercialInformation--row{
          flex-direction: column;
          align-items: flex-start;
      }
  
     .cardInfo-column .card-items-info--group {
        max-width: 100% !important;
      }
  }