.OpportunityModal-container{
   width: 100%;
   height: 100%;
   display: flex;
   flex-direction: column;
   gap: 30px;
}

.OpportunityModal-header-container{
   width: 100%;
   height: 350px;
   display: grid;
   grid-template-rows: repeat(4, 1fr);
   grid-template-columns: repeat(6, 1fr);
}

.OpportunityModal-banner-container{
   grid-row: 1/4;
   grid-column: 1/-1;
}

.OpportunityModal-logo-container{
   grid-row: 2/5;
   grid-column: 1/3;
   padding: 20px;
}

.OpportunityModal--bannerImg, .OpportunityModal--logoImg{
   width: 100%;
   height: 100%;
   object-fit: cover;
   border-radius: var(--border-radius-md);
}

.OpportunityModal-text-container{
   display: flex;
   flex-direction: column;
   gap: 6px;
}

.OpportunityModal--title{
   font-size: var(--fs-h4);
   color: var(--color-text-secondary);
}

.OpportunityModal-description{
   font-size: var(--fs-body);
   color: var(--color-text-primary);
}


.OpportunityModal-see-more--text{
   font-size: var(--fs-body);
   color: var(--color-text-secondary);
   text-decoration: underline;
}

.OpportunityModal-footer-container{
   display: flex;
   align-items: center;
   justify-content: center;
   gap: 10px;
   width: 100%;
}

@media screen and (max-width: 768px) {
   .OpportunityModal-logo-container{
      grid-column: 3/5;
      grid-row: 3/5;
      padding: 0;
      min-width: 250px;
   }

   .OpportunityModal-text-container{
      align-items: center;
   }

   .OpportunityModal--title, .OpportunityModal-description{
      text-align: center;
      text-wrap: balance;
   }

   .OpportunityModal-see-more--text{
      text-align: center;
   }
}