.mainSwitch-styling{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 16px;
    margin-bottom: 20px;
}

.switch-root-styling{
    width: fit-content;
}

.discountsForm__SwitchDiv{
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid grey;
    border-radius: var(--border-radius-md);
    box-shadow: var(--box-shadow);
    height: 100%;
}