.coursesCards-father-div {
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius-sm);
    margin: 16px 0;
    text-decoration: none;
    z-index: 0;
    overflow: hidden;
    width: 100%;
    position: relative;
    cursor: pointer;
}

.coursesCards-father-div:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: -16px;
    right: -16px;
    background-color: var(--color-secondary-light); /* #00838d */
    height: 40px;
    width: 40px;
    border-radius: 32px;
    transform: scale(1);
    transform-origin: 50% 50%;
    transition: transform 0.25s ease-out;
}

.coursesCards-father-div:hover:before {
    transform: scale(21);
}

.coursesCards-father-div:hover p {
    transition: all 0.3s ease-out;
}

.coursesCards-div-date {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: var(--fs-body);
    width: 15%;
    padding: 24px;
    background-color: var(--color-secondary);
}

.coursesCards-text-span-date {
    font-size: var(--fs-body);
    color: var(--color-text-tertiary);
}

.coursesCards-span-date {
    font-size: var(--fs-h3);
    color: var(--color-text-tertiary);
}

.coursesCards-description-color {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 24px;
    width: 100%;
}

.coursesCards-description {
    display: flex;
    align-items: center;
    font-size: var(--fs-body);
    color: var(--color-text-primary);
    padding: 0 0 0 8px;
    width: 90%;
}

.coursesCards-buttons-div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: auto;
}

/* Card popping up effect */
.coursesCards-father-div {
    transition: all 0.2s ease-out;
    box-shadow: var(--box-shadow);
    top: 0;
}

.coursesCards-father-div:hover {
    top: -4px;
}

@media screen and (min-width: 1201px) and (max-width: 1400px) {
    .coursesCards-father-div {
        margin: 16px 0;
        overflow: hidden;
        width: 100%;
    }

    .coursesCards-father-div:before {
        top: -16px;
        right: -16px;
        height: 40px;
        width: 40px;
        border-radius: 32px;
    }

    .coursesCards-father-div:hover:before {
        transform: scale(21);
    }

    .coursesCards-description {
        padding: 0 0 0 8px;
        width: 90%;
    }

    .coursesCards-father-div:hover {
        top: -4px;
    }
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
    .coursesCards-father-div {
        margin: 16px 0;
        overflow: hidden;
        width: 100%;
    }
}

@media (max-width: 768px) {
    .coursesCards-father-div {
        flex-direction: column;
        align-items: center;
    }

    .coursesCards-div-date {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 8px;
    }

    .coursesCards-text-span-date {
        font-size: var(--fs-h3);
        color: var(--color-text-tertiary);
    }

    .coursesCards-span-date {
        margin-left: 8px;
    }

    .coursesCards-description-color {
        flex-direction: column;
    }

    .coursesCards-description {
        justify-content: center;
        width: 100%;
        margin: 8px 0;
        font-size: var(--fs-small);
    }

    .coursesCards-buttons-div {
        margin: 8px;
    }

    .coursesCards-father-div:before {
        display: none;
    }
}
