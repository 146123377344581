.sidebarLogo__container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
}

.sidebarLogo__image {
    height: 32px;
    width: auto;
}